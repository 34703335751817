// If you want to override variables do it here
@import 'variables';

// Import styles
@import '~@coreui/coreui/scss/coreui.scss';

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import 'custom';

// ie fixes
@import 'ie-fix';
.btn-primary {
  color: #fff;
  background-color: #003c53;
  border-color: #003c53;
}

.btn-primary:hover {
  color: #fff;
  background-color: #003c53ab;
  border-color: #003c53ab;
}
.bg-primary {
  background-color: #154e63 !important;
}

a {
  color: #003c53;
}

.sidebar .nav-link:hover {
  color: #fff;
  background: #003c53;
}

html body .app.flex-row.align-items-center {
  height: 100vh;
  background-image: url('/assets/img/bg.png');
  background-size: cover;
}
